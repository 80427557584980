<template>
  <div class="columns">
    <div class="column is-3" v-if="$screen.width > 768">
      <banner-left
        :pasiva="getLinea.pasiva ? true : false"
        :image="image"
        img-class="tren-img"
        titulo="Tren de crédito"
        texto="El seguimiento del crédito desde su prospección hasta su 
        finalización es un sistema de control que permite monitorear los 
        créditos en cada momento."
      />
    </div>
    <div class="column is-9 text-left">
      <div class="columns">
        <div class="column is-12 text-left">
          <p class="text-2xl font-bold">
            Cambiar estatus de línea
          </p>
          <p class="has-text-grey-light">
            Línea #{{ $route.params.idLinea }} - Estatus actual:
            <b>{{ getLinea.status_linea_directo.nombre }}</b>
          </p>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-12">
          <b-tag
            class="cursor-pointer tag-clickable mr-3 mb-2"
            @click.native="
              activeTag = activeTag == grupo.nombre ? null : grupo.nombre
            "
            :class="{ 'is-active': grupo.nombre == activeTag }"
            v-for="grupo in grupos"
            :key="grupo.nombre"
          >
            {{ grupo.nombre }}
          </b-tag>
        </div>
        <div
          class="column text-right is-3"
          v-for="status in lineaGrouped"
          :key="status.id"
        >
          <div
            class="card-status h-full p-3 has-border-radius has-background-white-ter text-left border-2 has-border-white-ter relative"
            :class="{ active: getLinea.status_linea_directo.id == status.id }"
            @click="changeStatus(status)"
          >
            <p class="font-medium mb-8">{{ status.nombre }}</p>
            <div class="text-right absolute bottom-0 right-0 p-1">
              <b-icon
                size="is-large"
                :icon="setLineaIcon(status.nombre).icon"
                :class="setLineaIcon(status.nombre).colorIcon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <portal to="modalContainer">
      <b-modal
        v-if="is_change_status"
        :active.sync="is_change_status"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-cambio-status-linea :status="status_active" />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import BannerLeft from "@/components/banners/BannerLeft";
import ModalCambioStatusLinea from "@/components/modals/ModalCambioStatusLinea";
import { mapGetters } from "vuex";
import { setLineaIcon } from "@/vendors/helpers";

export default {
  name: "CambioStatusLinea",
  components: {
    BannerLeft,
    ModalCambioStatusLinea
  },
  data() {
    return {
      image: "/images/tren_credito.svg",
      setLineaIcon: setLineaIcon,
      activeTag: null,
      is_change_status: false,
      status_active: null,
      grupos: [
        {
          nombre: "Promoción",
          nombres: ['Prospecto', 'Listo para analizar', 'En proceso de Integración', 'Con expediente integrado']
        },
        {
          nombre: "Crédito",
          nombres: ['Agendado para autorización', 'En proceso de análisis', 'Análisis concluido', 'Expediente correcto']
        },
        {
          nombre: "Instancias",
          nombres: ['Autorizada', 'Autorizada con condiciones', 'Sin decisión', 'Rechazado']
        },
        {
          nombre: "Proceso jurídico",
          nombres: ['Contrato en proceso', 'Con contrato firmado', 'Listo para Firma']
        },
        {
          nombre: "En mesa",
          nombres: ['Listo para disponer', 'Dispuesto']
        },
        {
          nombre: "Miscelánea",
          nombres: ['Con problemas']
        },
        {
          nombre: "En tratamiento",
          nombres: ['Atenta nota', 'En proceso de recalificación', 'Recalificada', 'Finalizado', 'Castigado', 'Irrecuperable']
        }
      ]
    };
  },
  methods: {
    changeStatus(status) {
      this.is_change_status = true;
      this.status_active = status;
    }
  },
  computed: {
    ...mapGetters("lineascredito", ["getLinea"]),
    ...mapGetters("catalogos", ["getLineaStatus"]),
    lineaGrouped() {
      if (this.activeTag != null) {
        return this.getLineaStatus.filter(x => {
          return this.grupos
            .find(x => x.nombre == this.activeTag)
            .nombres.includes(x.nombre);
        });
      }
      return this.getLineaStatus;
    }
  },
  async mounted() {
    if (this.getLineaStatus == null) {
      await this.$store.dispatch("catalogos/getLineaStatus");
    }

    this.grupos.forEach(grupo => {
      if (grupo.ids.includes(this.getLinea.status_id)) {
        this.activeTag = grupo.nombre;
      }
    });
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
.tren-img {
  padding-bottom: 3rem;
  overflow: hidden;
  > img {
    transform: scale(2);
  }
}
.card-status {
  min-height: 6.7rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    border-color: $primary;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
  &.active {
    pointer-events: none;
    opacity: 0.7;
    background-color: $blue-variant !important;
    border-color: $blue-variant !important;
  }
}
</style>
